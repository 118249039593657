.App {
  text-align: center;
  background-color: #3b3058;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.roundedborder {
  border-radius: 20%;
}


.meld {
  align-items: end;
 
}

.bgcolor-1 {
  background-color: #54457f;
}

.bgcolor-2 {
  background-color: #d8d4f2;
}

.bgcolor-3 {
  background-color: #c4b2bc;
}

.bgcolor-4 {
  background-color: #5bc0be;
}

.fgcolor-1 {
  color: #54457f;
}

.fgcolor-2 {
  color: #d8d4f2;
}

.fgcolor-3 {
  color: #c4b2bc;
}

.fgcolor-4 {
  color: #5bc0be;
}

.infoContainer {
  display: flex;
  text-align: center;
}


span.quizPanel{
  max-width: 500px;
  margin: 40px auto 80px;
  width: 100%;
  padding: 0 20px;
  display: inline-block;
  border-radius: 20px;
  color: #5bc0be;
}


form.quizPanel{
  margin: 0;
  padding: 30px 40px;
  border: 2px solid #8561bb;
  width: 100%;
  border-radius: 20px;
  overflow: auto;
}

form.quizPanel table{
  width: 100%;
}

form.quizPanel table tbody tr td:first-child{
  color:#fff;
 padding: 10px
}

form.quizPanel table tr th {
  padding: 10px 10px 20px
}


.infoPanel {
  display: inline-block;
  /* white-space: nowrap; */
}

.infoRow {
  padding: 0 10px 20px;
  font-family: Helvetica;
  /* white-space: nowrap; */
  display: inline-block;
  text-align: center;
}

.infoTitle {
  padding-bottom: 10px ;
  vertical-align: top;
  color: #5bc0be;
  font-family: "Lato", sans-serif;
  min-height: 65px;
}

.infoElement {
  display: block;
  margin: auto;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 1px;
}

.tilePanel{
  max-width: 1400px;
  margin: 80px auto 40px;
  padding-top: 94px;
}

.tileContainer {
  margin: 20px 20px 20px 20px;
  float: center;
}

.tile {
  max-height: 100px;
  max-width: 80px;
  display: inline-block;
  white-space: normal;
}

.tile-small {
  max-height: 50px;
  max-width: 40px;
  display: inline-block;
  white-space: normal;
}

.allTiles {
  width: auto;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  border-radius: 20px;
  border: 2px solid #8561bb;
  display: flex;
  background-color: #271a3b;
  justify-content: center;
  flex-wrap: wrap;
}

.infoContainer {
  border-radius: 20px;
  border: 2px;
}

.inline {
  display: inline-block;
  white-space: nowrap;
}

.winningTile {

  border: 2px solid #ff0000;
  border-radius: 9px;
}

.calledTile {
  transform: rotate(90deg) translate(17%);
  margin-left: 11px;
  margin-right: 11px;
}

.alignBottom {
  position: absolute;
  bottom: 0;
}

.floatRight {
  float: right;
}

.quizBox {
  position: relative;
  top: 5px;
  margin-top: 5px;
  width: 50px;
  background-color: #54457f;
  color: #d8d4f2;
  outline: none;
  border: 0;
  border-bottom: 1px solid #d8d4f2;
  text-align: center;
}

table {
  margin-bottom: 30px;
}

table td {
  padding: 2px 2px 2px 2px;
  border-bottom: 2px solid #c4b2bc;
}

table th {
  padding: 5px 20px 5px 20px;
  border-bottom: 1px solid #d8d4f2;
}

button {
  background-color: #5bc0be;
  border: none;
  color: white;
  font-family: "Lato", sans-serif;
  padding: 10px;
  text-align: center;
  margin: 4px 2px;
}

.yakuList {
  border-radius: 20px;
  border: 2px;
  color: #5bc0be;
  background-color: #54457f;
  width: 300px;
  height: 300px;
  position: relative;
  bottom: 350px;
  left: 350px;
}

.checkAnswer {
  border-radius: 15px;
  font-size: 15px;
}

.checkAnswer:disabled {
  border-radius: 15px;
  font-size: 15px;
  margin-right: 0px;
  background-color: #244c4c;
}



form .btns{
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

form .btns button{
  padding: 10px 20px;
  cursor: pointer;
}

form .btns button:hover{
  background: #fff;
  color:#3B3058
}

.newHand {
  border-radius: 15px;
  font-size: 15px;
}

.answerText {
  font-family: "Lato";
  text-shadow: 1px 1px 0 #000;
}

.unselectable{
  -ms-user-select: none;
  user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.correctAnswer {
  color: #38ed38;
}

.wrongAnswer {
  color: red;
}

.ignoredAnswer {
  color: gray;
}

.winRatePanel {
  right: 5px;
  background-color: #54457f;
  border: none;
  color: white;
  font-family: "Lato", sans-serif;
  padding: 15px 20px;
  text-align: center;
  margin: 2px 4px;
  border-radius: 5px;
}

.spaced {
  margin-right: 10px;
}

.tooltip [class$="inner"] {
  background-color: #5bc0be;
  border: 1px solid #5bc0be;
}

.tooltip [class$="arrow"] {
  opacity: 0%;
}

.menu-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  top: 98px;
  overflow: auto;
  width: 300px;
  height: max-content;
}

.OptionsMenu {
  color: #5bc0be;
  background-color: #54457f;
  padding-top: 10px;
  left: 0px;
  top: 98px;
  font-family: "Lato", sans-serif;
  padding: 30px;
  z-index: 999;
}

.form-check-input{
  margin-right: 10px;
}

.menuOption {
  display: inline-block;
  text-align: center;
  margin: 10px;
  min-width: 200px;
  font-size: 17px !important;
}

.menuOption.small {
  width: 200px;
  min-width: 200px;
}

.menuOption.large {
  margin-top: 20px;
}

.OptionsMenu h4{
  color:#fff
 }


.header .cust-container{
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  flex-direction: row-reverse;
  align-items: center;
 }
 
 .header{
  background: #806fab;
  position: fixed;
  width: 100%;
  z-index: 99;
 }
 
 .allTiles > div  img{
 padding: 1px
 }
 
 .allTiles .meld{
  padding-left: 20px
 }
 
 .row{
  padding: 0 20px !important;
  margin: 0 !important;
 }

 .row>*{
  padding: 0 !important;

 }

 .infoContainer > div {
  flex:1;
  padding-bottom: 30px
 }
 
 .infoPanel {
  width: 100%;
  max-width: 800px;
  margin:0;
  padding: 30px 0 20px;
  border-radius: 20px;
  border:2px solid #8561bb
 }
 
 .infoContainer {
  max-width: 700px;
  margin: 0 auto
 }

 .scrollBtn{
  position: fixed;
 bottom: 20px;
 right: 20px;
 border-radius: 5px;
 box-shadow: 0 9px 23px 4px #00000085;
 z-index: 9999;

}

.scrollBtn img{
  transform: rotate(-180deg)
}

.scrollBtn.up img{
 transform: rotate(0deg)
}

.scrollBtn.up {
   top: 115px;
   bottom: auto;
}


 
 @media (max-width:767px){
  .infoContainer > div {
     flex: unset;
     width: 33.33%;
 }
  
  .infoContainer{
   justify-content: center;
   flex-wrap: wrap
  }

  span.quizPanel{
    margin-bottom: 40px;
  }

  .tilePanel{
    margin-top: 40px;
  }

  .allTiles .meld{
    padding-left: 0
   }
  

  .meld{
  display: flex;

  }

  
 }
 
 @media (max-width:600px){
   .infoContainer > div {
     flex: unset;
     width: 50%;
 }
  
  .infoTitle 
  {
   min-height: 53px
  }

  form.quizPanel{
    padding: 25px 20px;
  }
 }

 @media (max-width:479px){
    .winRatePanel label{
      font-size: 12px;
    }

    .winRatePanel{
      font-size: 12px;
      padding: 10px 12px;
      margin: 0;
    }

    .OptionsButton{
      padding: 7px;
      font-size: 1px;
      line-height: 1px;
    }

    button.OptionsButton img {
      height: 21px;
      width: 22px;
      margin: 0;
  }

  .header .cust-container{
    padding: 20px 15px;
  }

  .menu-wrap {
    top:78px
  }

  .allTiles .meld img{
    padding: 10px 11px
  }
  
  .allTiles > div img {
      padding: 10px 11px
  }

  .imageC{
    margin: 0;
  }
}

 @media (max-width:340px){
  .winRatePanel{
    padding: 10px 8px;
  }
 }

 button.testhelp {
  height: 46px;
  width: 44px;
  margin-left: 20px;
  font-size: 20px;
  padding: 10px;
}

.headerButtons {
  display: flex;
  align-items: center;
}

/* The side navigation menu */
.sidenav {
  height: 100%; 
  width: 60%; 
  min-width: 620px;
  position: fixed; 
  z-index: 1; 
  top: 60px; 
  left: 0;
  background-color: #1F152F;
  overflow-x: hidden; 
  padding-top: 60px; 
  padding-bottom: 60px; 
  padding-left: 60px; 
  text-align: left;
  color: white;
}

@media screen and (max-width: 600px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 10px;}
  .sidenav {
    width: 100%; 
    overflow-x: auto; 
    padding-right: 60px; 
    padding-left: 20px; 
    overflow-x: scroll;
    zoom: 0.6;
    padding-top: 100px;
  }
}

@media screen and (max-height: 400px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 10px;}
  .sidenav {
    width: 100%; 
    overflow-x: auto; 
    padding-right: 60px; 
    padding-left: 20px; 
    overflow-x: scroll;
    zoom: 0.8;
    padding-top: 80px;
  }
}

.closedSideNav {
  visibility: hidden;
}

td {
  text-align: center;
}


[aria-hidden="true"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}